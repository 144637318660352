import { useNavigate } from "react-router-dom";

const ParentComponent = ({ selectedPhoto }) => {
  const navigate = useNavigate();

  const handleEditPhoto = () => {
    if (!selectedPhoto) {
      alert("写真を選択してください！");
      return;
    }
    navigate(`/edit/${selectedPhoto.id}`);
  };

  return (
    <Button type="primary" onClick={handleEditPhoto}>
      Edit Photo
    </Button>
  );
};

export default ParentComponent; // Make sure it's exported as default
