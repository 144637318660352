import React, { useEffect, useState } from "react";
import "./App.css";
import { db, auth } from "./FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Table, Button, Spin, theme, Select, Input } from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";

// Table column definitions
const columns = [
  {
    title: "ファイル名",
    dataIndex: "photopath",
    key: "photopath",
    width: "20%",
    sorter: (a, b) => a.photopath.localeCompare(b.photopath),
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "品種名",
    dataIndex: "cultiverName",
    key: "cultiverName",
    width: "20%",
    sorter: (a, b) => a.cultiverName.localeCompare(b.cultiverName),
    sortDirections: ["ascend", "descend", "ascend"],

    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="品種名で検索"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          検索
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          リセット
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record.cultiverName
        ? record.cultiverName.toLowerCase().includes(value.toLowerCase())
        : false,
  }
  ,
  {
    title: "平均気孔開度",
    dataIndex: "aperture",
    key: "aperture",
    width: "8%",
    sorter: (a, b) => a.aperture - b.aperture,
    sortDirections: ["ascend", "descend", "ascend"],
    render: (value) => Number(value).toFixed(2), // ← これを追加！
  },
  {
    title: "気孔の数（個）",
    dataIndex: "size",
    key: "size",
    width: "8%",
    sorter: (a, b) => a.size - b.size,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "撮影場所",
    dataIndex: "location",
    key: "location",
    width: "12%",
    sorter: (a, b) => a.location?.localeCompare(b.location ?? ""),
    sortDirections: ["ascend", "descend", "ascend"],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="撮影場所で検索"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={confirm} size="small" style={{ width: 90, marginRight: 8 }}>
          検索
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          リセット
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record.location?.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "重要度",
    dataIndex: "important",
    key: "important",
    width: "8%",
    sorter: (a, b) => a.important?.localeCompare(b.important ?? ""),
    sortDirections: ["ascend", "descend", "ascend"],
    filters: [
      { text: "なし", value: "" },
      { text: "★", value: "★" },
      { text: "★★", value: "★★" },
      { text: "★★★", value: "★★★" },
    ],
    onFilter: (value, record) => record.important === value,
  },
  {
    title: "自由記入",
    dataIndex: "freeText",
    key: "freeText",
    width: "20%",
    sorter: (a, b) => a.freeText?.localeCompare(b.freeText ?? ""),
    sortDirections: ["ascend", "descend", "ascend"],
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="自由記入で検索"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={confirm} size="small" style={{ width: 90, marginRight: 8 }}>
          検索
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          リセット
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record.freeText?.toLowerCase().includes(value.toLowerCase()),
  },
];
function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

function Download() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedType, setSelectedType] = useState("aperture"); // デフォルトの選択
  const [user, setUser] = useState(null); // Initialize user state as null
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [selectedResultFields, setSelectedResultFields] = useState(["aperture"]);
  const getDynamicColumns = () => {
    const maxColumns = Math.max(...posts.map(post => post.results?.length || 0));

    return selectedResultFields.flatMap(field =>
      Array.from({ length: maxColumns }, (_, i) => ({
        title: `${field}_${i + 1}`,
        key: `${field}_${i + 1}`,
        dataIndex: `${field}_${i + 1}`,
        width: "8%",
        render: (_, record) => {
          const res = record.results?.[i];
          const val = res?.[field];
          return typeof val === "number" ? val.toFixed(2) : val ?? "";
        }
      }))
    );
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe(); // Cleanup function
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setLoading(true);
        try {
          const email = user.email;
          const snapshot = await getDocs(collection(db, email));
          setPosts(
            snapshot.docs
              .filter(doc => doc.id !== "_settings") // ← 🔥これを追加！
              .map((doc) => {
                const data = doc.data();
                const resultsWithId = (data.results || []).map((item, idx) => {
                  const assignedId = item.id !== undefined && item.id !== "" ? item.id : idx.toString();
                  return {
                    ...item,
                    id: assignedId,
                  };
                });

                return {
                  key: doc.id,
                  ...data,
                  results: resultsWithId,
                };
              })
          );

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [user]);

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleDownload = () => {
    if (selectedType === "image_annotated") {
      selectedData.forEach((doc) => {
        drawAndDownloadImage(doc, true);
      });
    } else if (selectedType === "image_original") {
      selectedData.forEach((doc) => {
        drawAndDownloadImage(doc, false);
      });
    } else {
      const csvData = getData(selectedType);
      fetchDataCSV(csvData);
    }
  };

  const drawAndDownloadImage = (doc, showBoundingBox) => {
    if (!doc.results || doc.results.length === 0) return;

    const width = 3840;
    const height = 2160;

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = `https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
      doc.userName
    )}%2F${encodeURIComponent(doc.cultiverName)}%2F${encodeURIComponent(doc.photopath)}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);

      if (showBoundingBox) {
        doc.results.forEach((item, index) => {
          const { left, top, right, bottom } = item.location;
          const boxWidth = right - left;
          const boxHeight = bottom - top;
          const color = item.title === "open" ? "#ff0000" : "#0000ff";

          ctx.strokeStyle = color;
          ctx.lineWidth = 2;
          ctx.strokeRect(left, top, boxWidth, boxHeight);

          ctx.font = "16px Arial";
          ctx.fillStyle = color;

          ctx.textBaseline = "bottom";
          ctx.textAlign = "left";
          ctx.fillText(`${item.aperture?.toFixed(2)}µm`, left, top - 6);

          ctx.textBaseline = "bottom";
          ctx.textAlign = "right";
          ctx.fillText(`${item.id ?? index + 1}`, left + boxWidth - 4, top + boxHeight - 4);
        });
      }

      const suffix = showBoundingBox ? "_annotated" : "_original";
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpeg");
      link.download = `${doc.photopath.replace(/\.[^/.]+$/, "")}.jpg`;
      link.click();
    };
  };


  const downloadJSON = () => {
    const fileName = `${getCurrentDateTime()}.json`;
    const data = new Blob([JSON.stringify(selectedData, null, 2)], {
      type: "application/json",
    });
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = jsonURL;
    link.setAttribute("download", fileName);
    link.click();
    URL.revokeObjectURL(jsonURL);
  };

  const getData = () => {
    let csvContent = "";
    const maxColumns = Math.max(...selectedData.map((doc) => doc.results?.length || 0));

    // 🧱 表示Tableに準拠した固定ヘッダー
    const staticHeaders = columns.map(col => col.dataIndex); // ← columnsの順序通り！

    // 🛠 選択済みフィールドを使った動的ヘッダー（results）
    const dynamicHeaders = selectedResultFields.flatMap(field =>
      Array.from({ length: maxColumns }, (_, i) => `${field}_${i + 1}`)
    );

    csvContent += [...staticHeaders, ...dynamicHeaders].join(",") + "\n";

    // 🧾 データ行を生成
    selectedData.forEach((doc) => {
      const row = staticHeaders.map((field) => {
        const val = doc[field];
        return typeof val === "number" ? val.toString() : `"${val ?? ""}"`; // CSV対応
      });

      const resultValues = selectedResultFields.flatMap(field =>
        Array.from({ length: maxColumns }, (_, i) => {
          const res = doc.results?.[i];
          return res?.[field] ?? "";
        })
      );

      csvContent += [...row, ...resultValues].join(",") + "\n";
    });

    return csvContent;
  };


  useEffect(() => {
    if (
      ["aperture", "area", "axisLong", "axisShort", "confidence"].includes(selectedType)
    ) {
      setSelectedResultFields([selectedType]);
    }
  }, [selectedType]);


  const fetchDataCSV = (csvContent) => {
    // 選択されたフィールドをファイル名に組み込む
    const fieldTag = selectedResultFields.length > 0 ? selectedResultFields.join("_") : "all";
    const filename = `StomataStorage_${fieldTag}_${getCurrentDateTime()}.csv`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };



  const selectedData = posts.filter((doc) => selectedRowKeys.includes(doc.key));

  return (
    <>
      <div
        style={{ padding: 24, background: colorBgContainer, height: "100%" }}
      >
        {loading && (
          <div className="spin">
            <Spin size="large" tip="Loading" />
          </div>
        )}
        {!loading && (
          <>
            {!user ? (
              <Navigate to={"/"} />
            ) : (
              <>
                <Select
                  defaultValue="aperture"
                  style={{ width: 400, marginBottom: 16 }}
                  onChange={(value) => setSelectedType(value)}
                  options={[
                    {
                      label: <span>pixel</span>,
                      title: 'pixel',
                      options: [
                        {
                          label: <span>axisLong</span>,
                          value: 'axisLong',
                        },
                        {
                          label: <span>axisShort</span>,
                          value: 'axisShort',
                        },
                      ],
                    },
                    {
                      label: <span>µm</span>,
                      title: 'µm',
                      options: [
                        {
                          label: <span>aperture</span>,
                          value: 'aperture',
                        },
                      ],
                    },
                    {
                      label: <span>µ㎡</span>,
                      title: 'µ㎡',
                      options: [
                        {
                          label: <span>area</span>,
                          value: 'area',
                        },
                      ],
                    },
                    {
                      label: <span>jpeg</span>,
                      title: 'image',
                      options: [
                        {
                          label: <span>jpeg (バウンディングボックスあり)</span>,
                          value: 'image_annotated',
                        },
                        {
                          label: <span>jpeg (バウンディングボックスなし)</span>,
                          value: 'image_original',
                        }
                      ],
                    },
                  ]}
                />
                <Button
                  size="large"
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleDownload}
                  disabled={!selectedRowKeys.length}
                >
                  ダウンロード
                </Button>
                <Table
                  size="small"

                  columns={columns}
                  dataSource={posts}
                  rowSelection={{
                    selectedRowKeys,
                    onChange: handleSelectChange,
                  }}
                  pagination={false}
                  scroll={{ y: 800 }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div style={{ overflowX: 'auto' }}>
                        <table style={{ borderCollapse: "collapse" }}>
                          <thead>
                            <tr>

                              {record.results?.map((_, i) => (
                                <th key={i} style={{ textAlign: "center", padding: "4px 8px" }}>
                                  {`${selectedResultFields[0]}_${i + 1}`}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {selectedResultFields.map(field => (
                              <tr key={field}>
                                {record.results?.map((item, i) => (
                                  <td key={i} style={{ textAlign: "center", padding: "4px 8px" }}>
                                    {typeof item[field] === "number"
                                      ? item[field].toFixed(2)
                                      : item[field] ?? ""}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Download;
