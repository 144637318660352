import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function EditPage() {
  const { photopath } = useParams(); // URLから photopath を取得
  const [imageSrc, setImageSrc] = useState(""); // 画像URLを管理するステート

    useEffect(() => {
    // 仮データ: これを実際のデータ取得ロジックに置き換えてください
    const mockUserName = "exampleUser"; // `selectedPhoto.userName` を想定
    const mockCultiverName = "exampleCultiver"; // `selectedPhoto.cultiverName` を想定

    if (photopath) {
      // Firebase Storage の画像URLを生成
      const imageUrl = `https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
        mockUserName
      )}%2F${encodeURIComponent(mockCultiverName)}%2F${encodeURIComponent(
        photopath
      )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`;

      setImageSrc(imageUrl); // ステートにセット
    }
  }, [photopath]);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Editing: {decodeURIComponent(photopath)}</h2> {/* URLエンコード解除 */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="Selected"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
              border: "1px solid #ddd",
            }}
          />
        ) : (
          <p>Loading image...</p>
        )}
      </div>
      {/* 保存ボタン（将来的に編集内容を保存する機能を追加可能） */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default EditPage;
