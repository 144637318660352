import React from "react";
import {
  HomeOutlined,
  FolderOpenFilled,
  LineChartOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
  BlockOutlined
} from "@ant-design/icons";

export const SidemenuData = [
  {
    title: "ホーム",
    icon: <HomeOutlined />,
    link: "/",
  },
  {
    title: "ストレージ",
    icon: <FolderOpenFilled />,
    link: "/storage/",
  },
  {
    title: "グラフ",
    icon: <LineChartOutlined />,
    link: "/charts/",
  },
  {
    title: "使い方",
    icon: <QuestionCircleOutlined />,
    link: "/usersManual/",
  },
  {
    title: "ダウンロード",
    icon: <DownloadOutlined />,
    link: "/Download/",
  },
  {
    title: "ギャラリーβ版",
    icon: <BlockOutlined />,
    link: "/BoundingBox/",
  },
];
