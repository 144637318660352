import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { Select, InputNumber, Modal, Table, Button, Input, Popover, theme, Switch, Spin, Slider } from "antd";
import { db, auth } from "./FirebaseConfig";
import { SketchPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore"; // Firestoreの更新用関数をインポート
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { onAuthStateChanged } from "firebase/auth";
import "./App.css";
import { getDoc } from "firebase/firestore"; // ← 追加
import { Timestamp } from "firebase/firestore"; // 🔥追加
import { usePosts } from "./usePosts";
import { ReloadOutlined , LeftOutlined, RightOutlined, EditOutlined, SettingOutlined, EyeOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

function BoundingBox() {
  const { posts, loading, user, refetch } = usePosts();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // 初期ズームレベル
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 }); // ドラッグの位置
  const [isDragging, setIsDragging] = useState(false); // ドラッグ状態
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [openColor, setOpenColor] = useState("#ff0000"); // 初期色: Open (赤)
  const [closeColor, setCloseColor] = useState("#0000ff"); // 初期色: Close (青)
  const actualWidth = 3840; // 画像の実サイズ
  const actualHeight = 2160;
  const displayWidth = 1000; // 表示サイズ
  const displayHeight = (actualHeight / actualWidth) * displayWidth;
  const scaleX = actualWidth / displayWidth; // 横方向スケール
  const scaleY = actualHeight / displayHeight; // 縦方向スケール
  const [hoveredBoxKey, setHoveredBoxKey] = useState(null); // ホバー中のボックス
  const [drawBoundingBox, setDrawBoundingBox] = useState(true);
  const [drawText, setDrawText] = useState(true);
  const [drawScaleBar, setDrawScaleBar] = useState(true);
  const [boxShadowWidth, setBoxShadowWidth] = useState(1.0);
  const [mode, setMode] = useState("view"); // "edit", "setting", "view"
  const [newBoxStart, setNewBoxStart] = useState(null);
  const [newBoxEnd, setNewBoxEnd] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedBoxKey, setSelectedBoxKey] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [lines, setLines] = useState([]); // 直線の一覧
  const [newLineStart, setNewLineStart] = useState(null);
  const [newLineEnd, setNewLineEnd] = useState(null);
  const [zoomOrigin, setZoomOrigin] = useState({ x: displayWidth / 2, y: displayHeight / 2 });
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [showCrosshair, setShowCrosshair] = useState(false);
  const [originalBoundingBoxes, setOriginalBoundingBoxes] = useState([]);
  const [originalLines, setOriginalLines] = useState([]);
  const [lineColor, setLineColor] = useState("black"); // 緑
  const [lineWidth, setLineWidth] = useState(0.2);
  const [shiftZPressed, setShiftZPressed] = useState(false);
  const [showPhotoList, setShowPhotoList] = useState(true);
  const currentIndex = posts.findIndex(p => p.photopath === selectedPhoto?.photopath);
  const [showBBoxTable, setShowBBoxTable] = useState(true);
  const [count, setCount] = useState({ value: 0 });
  const [drawingMode, setDrawingMode] = useState("none");
  const [mouseScreenPos, setMouseScreenPos] = useState({ x: 0, y: 0 }); // fixed表示用
  const [aPressed, setAPressed] = useState(false);
  const [polygonPoints, setPolygonPoints] = useState([]); // 現在描画中のポリゴン
  const [polygons, setPolygons] = useState([]); // 確定済みのポリゴン群

  const isCtrlOrCmdPressed = (e) => e.ctrlKey || e.metaKey;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "1") setShiftPressed(true);
      if (e.key === "2") setCtrlPressed(true);
      if (e.key.toLowerCase() === "a") setAPressed(true);
    };

    const handleKeyUp = (e) => {
      if (e.key === "1") setShiftPressed(false);
      if (e.key === "2") setCtrlPressed(false);
      if (e.key.toLowerCase() === "a") setAPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleCanvasClick = (e) => {
    if (drawingMode !== "polygon") return;

    const pos = getMousePositionOnImage(e);
    setPolygonPoints(prev => [...prev, pos]);
  };

  const handleCanvasDoubleClick = () => {
    if (drawingMode !== "polygon" || polygonPoints.length < 3) return;

    const newPoly = {
      id: Date.now(),
      points: polygonPoints,
    };

    setPolygons(prev => [...prev, newPoly]);
    setPolygonPoints([]); // クリアして次に備える
  };

  useEffect(() => {
    if (ctrlPressed && aPressed) setDrawingMode("polygon");
    else if (ctrlPressed) setDrawingMode("bbox");
    else if (shiftPressed) setDrawingMode("line");
    else setDrawingMode("none");
  }, [ctrlPressed, shiftPressed, aPressed]);

  useEffect(() => {
    if (posts.length > 0 && !selectedPhoto) {
      const latest = posts[posts.length - 1]; // 末尾が最新（descソート済み想定）
      handlePhotoClick(latest);
    }
  }, [posts]);

  const goToPreviousPhoto = () => {
    if (currentIndex > 0) {
      handlePhotoClick(posts[currentIndex - 1]);
    }
  };

  const goToNextPhoto = () => {
    if (currentIndex < posts.length - 1) {
      handlePhotoClick(posts[currentIndex + 1]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "1") setShiftPressed(true);
      if (e.key === "2") setCtrlPressed(true);
      if (e.key.toLowerCase() === "z" && shiftPressed) setShiftZPressed(true);
    };

    const handleKeyUp = (e) => {
      if (e.key === "1") setShiftPressed(false);
      if (e.key === "2") setCtrlPressed(false);
      if (e.key.toLowerCase() === "z") setShiftZPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [shiftPressed]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isCtrlOrCmdPressed(e) && e.key === 's') {
        e.preventDefault();
        handleSaveAllChanges(); // クラウド保存関数
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [boundingBoxes, lines, selectedPhoto]);

  const getMousePositionOnImage = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();

    // 表示上のマウス位置
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    // 表示上の中心点（transform-origin が center なので）
    const containerCenterX = displayWidth / 2;
    const containerCenterY = displayHeight / 2;

    // ズーム＋パン補正で実座標へ変換
    const logicalX = ((offsetX - containerCenterX - currentPosition.x) * actualWidth / displayWidth) / zoomLevel + actualWidth / 2;
    const logicalY = ((offsetY - containerCenterY - currentPosition.y) * actualHeight / displayHeight) / zoomLevel + actualHeight / 2;

    return { x: logicalX, y: logicalY };
  };


  // 編集モードでのボックス作成（mousedown）
  const handleCanvasMouseDown = (e) => {
    if (mode !== "edit") return;

    // 🛑 右クリックメニューを防止（必須！）
    e.preventDefault();

    // 👇 Hover中のやつを一旦解除！
    setHoveredBoxKey(null);

    const isRightClick = e.button === 2;
    const boxType = isRightClick ? "close" : "open";

    setNewBoxStart({ ...getMousePositionOnImage(e), type: boxType });
    setNewBoxEnd(null);
  };

  const handleCanvasMouseMove = (e) => {
    if (mode !== "edit" || !newBoxStart) return;
    setNewBoxEnd(getMousePositionOnImage(e));
  };


  // mouseup
  const handleCanvasMouseUp = () => {
    if (!newBoxStart) return;

    const finalEnd = newBoxEnd || newBoxStart;

    const width = Math.abs(finalEnd.x - newBoxStart.x);
    const height = Math.abs(finalEnd.y - newBoxStart.y);

    if (width < 5 || height < 5) {
      setNewBoxStart(null);
      setNewBoxEnd(null);
      return;
    }

    const left = Math.min(newBoxStart.x, finalEnd.x);
    const top = Math.min(newBoxStart.y, finalEnd.y);

    const newId = getNextId();
    const isClose = newBoxStart.type === "close";

    const newBox = {
      key: newId,
      id: newId.toString(),
      x: left,
      y: top,
      width,
      height,
      title: isClose ? "close" : "open",
      aperture: isClose ? 0.0 : 0.0,
      long: isClose ? 0.0 : 0.0,
      short: isClose ? 0.0 : 0.0,
    };

    setUndoStack(prev => [...prev, { boxes: boundingBoxes, lines }]);
    setBoundingBoxes(prev => [...prev, newBox]);
    setNewBoxStart(null);
    setNewBoxEnd(null);
  };
  useEffect(() => {
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener("contextmenu", preventContextMenu);
    return () => {
      window.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  const handleUndoBoundingBox = () => {
    if (undoStack.length === 0) return;
    const last = undoStack[undoStack.length - 1];
    setUndoStack((prev) => prev.slice(0, -1));
    setRedoStack((prev) => [...prev, { boxes: boundingBoxes, lines }]);
    setBoundingBoxes(last.boxes);
    setLines(last.lines);
  };

  const handleRedoBoundingBox = () => {
    if (redoStack.length === 0) return;
    const last = redoStack[redoStack.length - 1];
    if (!last?.boxes || !last?.lines) return; // 🔥 safety check！

    setRedoStack((prev) => prev.slice(0, -1));
    setUndoStack((prev) => [...prev, { boxes: boundingBoxes, lines }]);
    setBoundingBoxes(last.boxes);
    setLines(last.lines);
  };


  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((isCtrlOrCmdPressed(e)) && e.key === "z" && !e.shiftKey) {
        e.preventDefault();
        handleUndoBoundingBox();
      }

      if ((isCtrlOrCmdPressed(e)) && e.key === "y") {
        e.preventDefault();
        handleRedoBoundingBox();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [undoStack, redoStack, boundingBoxes]);



  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (selectedPhoto) {
      setImageLoaded(false); // 選択時にローディング開始
    }
  }, [selectedPhoto]);

  const convertToFirestoreFormat = (box) => ({
    title: box.title || "open",
    aperture: box.aperture || 0,
    axisLong: box.long || 0,
    axisShort: box.short || 0,
    confidence: box.confidence || 1,
    area: box.area || 0,
    id: box.id != null ? box.id.toString() : box.key.toString(), // 🔥修正：box.id 優先
    idClass: box.idClass || 0,
    stability: box.stability || 0,
    status: box.status || "CalcAperture",
    location: {
      left: box.x,
      top: box.y,
      right: box.x + box.width,
      bottom: box.y + box.height,
      empty: false,
    },
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedBoxKey !== null) {
      setEditModalVisible(true);
    }
  }, [selectedBoxKey]);

  const handlePhotoClick = async (photo) => {
    if (!photo) return;

    try {
      const photoRef = doc(db, photo.userName, photo.photopath);
      const snap = await getDoc(photoRef);

      if (!snap.exists()) {
        console.warn("データが存在しません");
        return;
      }

      const photoData = snap.data();

      setSelectedPhoto(photoData); // ← ステート更新！

      const boxes = (photoData.results || []).filter(item => item.location && item.location.left != null)
        .map((item, index) => {
          const id = item.id !== undefined && item.id !== "" ? Number(item.id) : index;
          return {
            key: id,
            id: id.toString(),
            x: item.location.left,
            y: item.location.top,
            width: item.location.right - item.location.left,
            height: item.location.bottom - item.location.top,
            title: item.title || "Unknown",
            aperture: item.aperture,
            long: item.axisLong,
            short: item.axisShort,
          };
        });

      setBoundingBoxes(boxes);
      setLines(photoData.lines ?? []);
      setOriginalBoundingBoxes(boxes);
      setOriginalLines(photoData.lines ?? []);


    } catch (error) {
      console.error("データ取得エラー:", error);
    }
  };
  const isChanged = () => {
    const boxesChanged = JSON.stringify(boundingBoxes) !== JSON.stringify(originalBoundingBoxes);
    const linesChanged = JSON.stringify(lines) !== JSON.stringify(originalLines);
    return boxesChanged || linesChanged;
  };

  const handleMouseDown = (e) => {
    if (mode === "edit" && ctrlPressed) {
      handleCanvasMouseDown(e);
    } else if (mode === "edit" && shiftPressed) {
      setNewLineStart(getMousePositionOnImage(e));
      setNewLineEnd(null);
    } else {
      setIsDragging(true);
      setStartDragPosition({ x: e.clientX - currentPosition.x, y: e.clientY - currentPosition.y });
    }
  };

  const handleMouseMove = (e) => {
    if (mode === "edit" && ctrlPressed) {
      handleCanvasMouseMove(e);
    } else if (mode === "edit" && shiftPressed && newLineStart) {
      let pos = getMousePositionOnImage(e);

      if (shiftZPressed) {
        const dx = pos.x - newLineStart.x;
        const dy = pos.y - newLineStart.y;

        // 角度を判断してスナップ
        if (Math.abs(dx) > Math.abs(dy)) {
          pos.y = newLineStart.y; // 水平に固定
        } else {
          pos.x = newLineStart.x; // 垂直に固定
        }
      }

      setNewLineEnd(pos);
    } else if (isDragging) {
      const x = e.clientX - startDragPosition.x;
      const y = e.clientY - startDragPosition.y;
      setCurrentPosition({ x, y });
    }


    // 🎯 クロスヘア用マウス位置を記録（display座標系）
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    setMousePos({ x: offsetX, y: offsetY });
    setMouseScreenPos({ x: e.clientX, y: e.clientY });

  };


  const handleMouseUp = () => {
    if (mode === "edit" && ctrlPressed) {
      handleCanvasMouseUp();
    } else if (mode === "edit" && shiftPressed && newLineStart) {
      const finalEnd = newLineEnd || newLineStart;
      const distance = Math.sqrt(
        Math.pow(finalEnd.x - newLineStart.x, 2) + Math.pow(finalEnd.y - newLineStart.y, 2)
      );

      const newLine = {
        id: Date.now(), // または increment ID
        start: newLineStart,
        end: finalEnd,
        distance: distance.toFixed(2),
      };

      setLines(prev => [...prev, newLine]);
      setUndoStack(prev => [...prev, { boxes: boundingBoxes, lines }]);
      setNewLineStart(null);
      setNewLineEnd(null);
    } else {
      setIsDragging(false);
    }
  };

  // ホイールでズーム
  const handleWheel = (e) => {
    e.preventDefault();

    const delta = e.deltaY > 0 ? -1 : 1;
    const newZoom = Math.min(50, Math.max(1, zoomLevel + delta));

    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    // ズーム中心を補間（zoomLevel: 1〜10 の間で変化）
    const t = Math.min(1, Math.max(0, (zoomLevel - 3) / 2)); // 3〜5で切り替え
    const baseX = offsetX * (1 - t) + displayWidth / 2 * t;
    const baseY = offsetY * (1 - t) + displayHeight / 2 * t;

    const zoomRatio = newZoom / zoomLevel;
    const newX = (baseX - displayWidth / 2 - currentPosition.x) * (zoomRatio - 1);
    const newY = (baseY - displayHeight / 2 - currentPosition.y) * (zoomRatio - 1);

    setZoomLevel(newZoom);
    setCurrentPosition((prev) => ({
      x: prev.x - newX,
      y: prev.y - newY,
    }));
  };

  const handleOpenColorChange = (color) => {
    setOpenColor(color.hex);
  };

  const handleCloseColorChange = (color) => {
    setCloseColor(color.hex);
  };

  const downloadWithBoundingBox = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = actualWidth;
    canvas.height = actualHeight;

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = `https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
      selectedPhoto.userName
    )}%2F${encodeURIComponent(selectedPhoto.cultiverName)}%2F${encodeURIComponent(
      selectedPhoto.photopath
    )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`;

    image.onload = () => {
      ctx.drawImage(image, 0, 0, actualWidth, actualHeight);

      if (drawBoundingBox) {
        boundingBoxes.forEach((box) => {
          const color = box.title === "open" ? openColor : closeColor;
          ctx.strokeStyle = color;
          ctx.lineWidth = boxShadowWidth * 5;
          ctx.strokeRect(box.x, box.y, box.width, box.height);

          if (drawText) {
            ctx.fillStyle = color;
            ctx.font = "16px Arial";
            ctx.fillText((box.key), box.x + box.width - 15, box.y + box.height - 5);
            ctx.fillText(box.aperture.toFixed(2) + "µm", box.x, box.y - 5);
          }
        });
      }

      if (drawScaleBar) {
        if (lines.length > 0) {
          ctx.strokeStyle = lineColor;
          ctx.fillStyle = lineColor;
          ctx.lineWidth = lineWidth;

          const micronPerPixel = 0.46; // ✅ ← 固定値に！

          lines.forEach((line) => {
            const startX = line.start.x;
            const startY = line.start.y;
            const endX = line.end.x;
            const endY = line.end.y;

            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            ctx.stroke();

            const labelX = (startX + endX) / 2;
            const labelY = (startY + endY) / 2 - 10;

            const labelText = `${line.distance} px / ${(line.distance * micronPerPixel).toFixed(2)} µm`;
            ctx.font = "20px Arial";
            ctx.fillText(labelText, labelX, labelY);
          });
        }
      }


      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpeg");
      link.download = selectedPhoto.photopath;
      link.click();
    };
  };

  const extractBoxImage = (box) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = `https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
      selectedPhoto.userName
    )}%2F${encodeURIComponent(selectedPhoto.cultiverName)}%2F${encodeURIComponent(
      selectedPhoto.photopath
    )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = box.width;
      canvas.height = box.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        img,
        box.x,
        box.y,
        box.width,
        box.height,
        0,
        0,
        box.width,
        box.height
      );

      const dataURL = canvas.toDataURL("image/png");
      setPreviewImage(dataURL); // 👈 ステートに保存
    };
  };
  const [jsonText, setJsonText] = useState("");

  useEffect(() => {
    setJsonText(JSON.stringify(boundingBoxes, null, 2));
  }, [boundingBoxes]);

  const getNextId = () => {
    const ids = boundingBoxes.map(b => Number(b.id ?? b.key)).filter(n => !isNaN(n));
    return ids.length > 0 ? Math.max(...ids) + 1 : 0;
  };

  useEffect(() => {
    const box = boundingBoxes.find(b => b.key === selectedBoxKey);
    if (box && selectedPhoto?.lines) {
      setLines(selectedPhoto.lines);
    }
  }, [selectedBoxKey, selectedPhoto]);

  useEffect(() => {
    if (!selectedPhoto) return;

    const results = selectedPhoto.results || [];
    const linesFromPhoto = selectedPhoto.lines || [];

    const boxes = results.filter(item => item.location && item.location.left != null)
      .map((item, index) => {
        const id = item.id !== undefined && item.id !== "" ? Number(item.id) : index;
        return {
          key: id,
          id: id.toString(),
          x: item.location.left,
          y: item.location.top,
          width: item.location.right - item.location.left,
          height: item.location.bottom - item.location.top,
          title: item.title || "Unknown",
          aperture: item.aperture,
          long: item.axisLong,
          short: item.axisShort,
        };
      });

    setBoundingBoxes(boxes);
    setLines(linesFromPhoto);
  }, [selectedPhoto]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "1") setShiftPressed(true);
    };
    const handleKeyUp = (e) => {
      if (e.key === "1") {
        setShiftPressed(false);
        setNewLineStart(null); // 🛠️ これを追加
        setNewLineEnd(null);   // 🔁 おまけでエンドもリセット
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const saveUserSettings = async () => {
    if (!user) return;
    const settingsRef = doc(db, user.email, "_settings"); // ← emailコレクションの _settings ドキュメント
    await setDoc(settingsRef, {
      drawBoundingBox,
      drawText,
      drawScaleBar,
      openColor,
      closeColor,
      boxShadowWidth,
    });
  };
  useEffect(() => {
    const loadUserSettings = async () => {
      if (!user) return;
      const settingsRef = doc(db, user.email, "_settings");
      const snap = await getDoc(settingsRef);
      if (snap.exists()) {
        const s = snap.data();
        setDrawBoundingBox(s.drawBoundingBox ?? true);
        setDrawText(s.drawText ?? true);
        setDrawScaleBar(s.drawScaleBar ?? true);
        setOpenColor(s.openColor ?? "#ff0000");
        setCloseColor(s.closeColor ?? "#0000ff");
        setBoxShadowWidth(s.boxShadowWidth ?? 1.0);
      }
    };
    loadUserSettings();
  }, [user]);

  const handleSaveAllChanges = async () => {
    if (!selectedPhoto) {
      alert("画像が選択されていません！");
      return;
    }

    try {
      const photoRef = doc(db, selectedPhoto.userName, selectedPhoto.photopath);
      const convertedBoxes = boundingBoxes.map(convertToFirestoreFormat);

      // 📊 平均 aperture
      const openBoxes = boundingBoxes.filter(box => box.title === "open");
      const aperture = openBoxes.length > 0
        ? openBoxes.reduce((sum, b) => sum + (b.aperture || 0), 0) / openBoxes.length
        : 0;

      // 🔢 合計件数
      const totalCount = boundingBoxes.length;

      // 🕒 更新時刻
      const updatedAt = Timestamp.now();

      await updateDoc(photoRef, {
        results: convertedBoxes,
        lines: lines.map(line => ({
          id: line.id.toString(),
          start: line.start,
          end: line.end,
          distance: line.distance,
        })),
        aperture,
        size: totalCount,
        updatedAt,
      });

      // ステート更新
      setOriginalBoundingBoxes(boundingBoxes);
      setOriginalLines(lines);

      alert("変更を保存しました！");
      // 🔽 これを try-catch でラップする！
      try {
        refetch();
      } catch (e) {
        console.warn("一覧リフレッシュ失敗:", e);
      }

    } catch (e) {
      console.error("保存エラー:", e);
      alert("保存に失敗しました...");
    }
  };
  useEffect(() => {
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener("contextmenu", preventContextMenu);
    return () => {
      window.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);


  const centerOnBox = (box) => {
    if (!box) return;

    const boxCenterX = box.x + box.width / 2;
    const boxCenterY = box.y + box.height / 2;

    // ⭐ 強制的にズームを10倍（1000%）に変更
    const targetZoom = 18;
    setZoomLevel(targetZoom);

    // 表示サイズの中心
    const displayCenterX = displayWidth / 2;
    const displayCenterY = displayHeight / 2;

    // 新しいパン位置を計算（ズームレベル込みで）
    const dx = ((boxCenterX * displayWidth / actualWidth / 0.98) - displayCenterX) * targetZoom;
    const dy = ((boxCenterY * displayHeight / actualHeight * 0.965) - displayCenterY) * targetZoom;

    setCurrentPosition({
      x: -dx,
      y: -dy,
    });
  };
  const resetZoomAndPosition = () => {
    setZoomLevel(1);
    setCurrentPosition({ x: 0, y: 0 });
  };


  // 画像とバウンディングボックスを描画
  return (
    <div
      style={{
        padding: 24,
        background: colorBgContainer,
        height: "98%",
      }}
    >
      {loading ? (
        <div className="spin">
          <Spin size="large" tip="Loading" />
        </div>
      ) : (
        <>
          {!user ? (
            <Navigate to={"/"} />
          ) : (
            <>
              <Modal
                open={editModalVisible}
                title="🛠️ バウンディングボックスを編集"
                onCancel={() => {
                  setEditModalVisible(false);
                  setSelectedBoxKey(null);
                }}
                onOk={async () => {
                  if (!selectedPhoto) {
                    alert("写真が選択されていません！");
                    return;
                  }

                  const photoRef = doc(db, selectedPhoto.userName, selectedPhoto.photopath);
                  const converted = boundingBoxes.map(convertToFirestoreFormat); // ← 事前に定義してるやつ
                  await updateDoc(photoRef, { results: converted });
                  setEditModalVisible(false);
                  setSelectedBoxKey(null);
                }}
                okText="保存"
                cancelText="キャンセル"
              >
                {(() => {
                  const box = boundingBoxes.find(b => b.key === selectedBoxKey);
                  if (!box) return null;

                  const updateField = (field, value) => {
                    setBoundingBoxes(prev =>
                      prev.map(b =>
                        b.key === selectedBoxKey ? { ...b, [field]: value } : b
                      )
                    );
                  };

                  return (
                    <>
                      <div style={{ marginBottom: 10 }}>Title:
                        <Select
                          value={box.title}
                          onChange={(value) => {
                            updateField("title", value);
                            if (value === "close") {
                              updateField("aperture", 0);
                              updateField("long", 0);
                              updateField("short", 0);
                            }
                          }}
                          options={[
                            { label: "open", value: "open" },
                            { label: "close", value: "close" },
                          ]}
                        />

                      </div>
                      <div style={{ marginBottom: 10 }}>Aperture:
                        <InputNumber
                          value={box.aperture}
                          onChange={(val) => updateField("aperture", val)}
                          step={0.01}
                        />
                      </div>
                      <div style={{ marginBottom: 10 }}>Long Axis:
                        <InputNumber
                          value={box.long}
                          onChange={(val) => updateField("long", val)}
                          step={0.1}
                        />
                      </div>
                      <div style={{ marginBottom: 10 }}>Short Axis:
                        <InputNumber
                          value={box.short}
                          onChange={(val) => updateField("short", val)}
                          step={0.1}
                        />
                      </div>
                      <Button
                        danger
                        onClick={async () => {
                          const updatedBoxes = boundingBoxes.filter(b => b.key !== selectedBoxKey);
                          setBoundingBoxes(updatedBoxes);
                          setSelectedBoxKey(null);
                          setEditModalVisible(false);

                          if (!selectedPhoto) {
                            alert("画像が選択されていません！");
                            return;
                          }

                          try {
                            const converted = updatedBoxes.map(convertToFirestoreFormat);
                            const photoRef = doc(db, selectedPhoto.userName, selectedPhoto.photopath);
                            await updateDoc(photoRef, { results: converted });

                          } catch (err) {
                            console.error("削除エラー:", err);
                            alert("保存に失敗しました...");
                          }
                        }}
                      >
                        このボックスを削除
                      </Button>
                    </>
                  );
                })()}
              </Modal>

              <div style={{ display: "flex", flexDirection: "row", height: "88vh" }}>
                {/* 左側: Photo List */}
                {showPhotoList && (
                  <div style={{ flex: 3, overflowY: "auto", borderRight: "1px solid #ddd" }}>
                    <h3>✏️ 画像一覧</h3>
                    <Table
                      dataSource={posts}
                      size="small" // ← これを追加！
                      rowKey={(record) => record.photopath}
                      rowClassName={(record) =>
                        selectedPhoto?.photopath === record.photopath ? "selected-row" : ""
                      }
                      width="100%"
                      pagination={false}
                      scroll={{ y: 800 }} // ← ここを追加！
                      columns={[
                        {
                          title: "ファイル名",
                          dataIndex: "photopath",
                          key: "photopath",
                          sorter: (a, b) => a.photopath.localeCompare(b.photopath),
                          sortDirections: ["ascend", "descend", "ascend"],
                          defaultSortOrder: "descend", // 👈 これを追加！
                          width: 200,
                          render: (text, record) => (
                            <span
                              onClick={() => handlePhotoClick(record)}
                              style={{
                                cursor: "pointer",
                                fontWeight: selectedPhoto?.photopath === record.photopath ? "bold" : "normal",
                                backgroundColor: selectedPhoto?.photopath === record.photopath ? "#ffec3d" : "inherit",
                                display: "inline-block",
                                padding: "2px 4px"
                              }}
                            >
                              {text}
                            </span>
                          ),
                        },
                        {
                          title: "品種名",
                          dataIndex: "cultiverName",
                          key: "cultiverName",
                          width: 200,
                          sorter: (a, b) => a.cultiverName.localeCompare(b.cultiverName),
                          sortDirections: ["ascend", "descend", "ascend"],
                          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                            <div style={{ padding: 8 }}>
                              <Input
                                placeholder="品種名で検索"
                                value={selectedKeys[0]}
                                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                                onPressEnter={() => confirm()}
                                style={{ marginBottom: 8, display: 'block' }}
                              />
                              <Button
                                type="primary"
                                onClick={() => confirm()}
                                size="small"
                                style={{ width: 90, marginRight: 8 }}
                              >
                                検索
                              </Button>
                              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                                リセット
                              </Button>
                            </div>
                          ),
                          filterIcon: (filtered) => (
                            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                          ),
                          render: (text, record) => (
                            <span
                              onClick={() => handlePhotoClick(record)}
                              style={{
                                cursor: "pointer",
                                fontWeight: selectedPhoto?.photopath === record.photopath ? "bold" : "normal",
                                backgroundColor: selectedPhoto?.photopath === record.photopath ? "#ffec3d" : "inherit",
                                display: "inline-block",
                                padding: "2px 4px"
                              }}
                            >
                              {text}
                            </span>
                          ),
                        },
                        {
                          title: "更新日時",
                          dataIndex: "updatedAt",
                          key: "updatedAt",
                          width: 160,
                          render: (val) => {
                            if (!val || !val.toDate) return "更新なし";
                            const d = val.toDate();
                            const pad = (n) => String(n).padStart(2, "0");
                            return `${d.getFullYear()}/${pad(d.getMonth() + 1)}/${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}`;
                          }
                        }
                      ]}
                    />
                  </div>
                )}

                {/* 中央: 画像とバウンディングボックス */}
                <div
                  style={{
                    flex: 4,
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#000",
                    cursor: isDragging ? "grabbing" : "grab",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onWheel={handleWheel}
                  onMouseEnter={() => setShowCrosshair(true)}
                  onMouseLeave={() => setShowCrosshair(false)}
                  onContextMenu={(e) => e.preventDefault()} // 👈 ← これ追加！！
                >
                  {selectedPhoto && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        backgroundColor: "yellow",
                        color: "black",
                        padding: "10px 20px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        zIndex: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      {/* 🔍 ズームレベル表示 */}
                      <div
                        style={{
                          position: "absolute",
                          top: 100,
                          right: 20,
                          padding: "5px 10px",
                          backgroundColor: "rgba(0,0,0,0.6)",
                          color: "#fff",
                          fontSize: "12px",
                          borderRadius: "5px",
                          zIndex: 999,
                          pointerEvents: "none",
                        }}
                      >
                        拡大率: {(zoomLevel * 100).toFixed(0)}%
                      </div>

                      <Button
                        icon={<LeftOutlined />}
                        onClick={goToPreviousPhoto}
                        disabled={currentIndex <= 0}
                      />
                      {selectedPhoto.photopath}（{selectedPhoto.cultiverName}）
                      <Button
                        icon={<RightOutlined />}
                        onClick={goToNextPhoto}
                        disabled={currentIndex >= posts.length - 1}
                      />
                    </div>
                  )}

                  {/* 設定フォーム */}
                  {mode === "setting" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100px",
                        right: "20px",
                        zIndex: 10, // Canvasより前面に配置
                        background: "rgba(255, 255, 255, 0.9)", // 半透明の背景
                        padding: "15px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        width: "300px",
                      }}
                      onMouseDown={(e) => e.stopPropagation()} // フォーム内でイベント伝播を停止

                    >
                      <div style={{ marginBottom: "10px" }}>
                        <Switch
                          checked={drawBoundingBox}
                          onChange={(checked) => setDrawBoundingBox(checked)}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                        <span>{" "}バウンディングボックス: </span>

                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Switch
                          checked={drawText}
                          onChange={(checked) => setDrawText(checked)}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                        <span>{" "}気孔開度: </span>

                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Switch
                          checked={drawScaleBar}
                          onChange={(checked) => setDrawScaleBar(checked)}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                        <span>{""} スケールバー: </span>

                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span>Open: </span>
                        <Popover
                          content={<SketchPicker color={openColor} onChange={handleOpenColorChange} />}
                          title="Select Open Color"
                          trigger="click"
                        >
                          <Button style={{ backgroundColor: openColor, color: "#fff" }}>Pick Color</Button>
                        </Popover>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span>Close: </span>
                        <Popover
                          content={<SketchPicker color={closeColor} onChange={handleCloseColorChange} />}
                          title="Select Close Color"
                          trigger="click"
                        >
                          <Button style={{ backgroundColor: closeColor, color: "#fff" }}>Pick Color</Button>
                        </Popover>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        線幅:{boxShadowWidth}
                        <Slider
                          min={0.2}
                          max={2.0}
                          value={boxShadowWidth}
                          onChange={(value) => setBoxShadowWidth(value)}
                          step={0.1}
                        />

                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span>Line: </span>
                        <Popover
                          content={<SketchPicker color={lineColor} onChange={(c) => setLineColor(c.hex)} />}
                          title="ラインの色を選択"
                          trigger="click"
                        >
                          <Button style={{ backgroundColor: lineColor, color: "#fff" }}>Pick Color</Button>
                        </Popover>
                      </div>

                      <div style={{ marginBottom: "10px" }}>
                        線幅: {lineWidth}
                        <Slider
                          min={0.2}
                          max={5.0}
                          step={0.1}
                          value={lineWidth}
                          onChange={setLineWidth}
                        />
                      </div>

                    </div>)}
                  {!imageLoaded && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0,0,0,0.5)", // 半透明にして雰囲気よく
                        zIndex: 20, // バウンディングボックスより前
                        pointerEvents: "none", // マウス操作を透過
                      }}
                    >
                      <Spin size="large" tip="画像を読み込み中..." />
                    </div>
                  )}
                  {(ctrlPressed || (shiftPressed && newLineStart)) && mode === "edit" && (
                    <>
                      {/* 縦線 */}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: `${mousePos.x}px`,
                          width: "1px",
                          height: "100%",
                          borderLeft: "1px dotted white",
                          zIndex: 999,
                          pointerEvents: "none",
                        }}
                      />
                      {/* 横線 */}
                      <div
                        style={{
                          position: "absolute",
                          top: `${mousePos.y}px`,
                          left: 0,
                          height: "1px",
                          width: "100%",
                          borderTop: "1px dotted white",
                          zIndex: 999,
                          pointerEvents: "none",
                        }}
                      />
                    </>
                  )}
                  <div
                    style={{
                      position: "fixed",
                      top: 140,
                      marginLeft: 10,
                      zIndex: 10,
                    }}
                  >
                    <Button
                      style={{ marginRight: "10px" }}
                      icon={showPhotoList ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                      onClick={() => setShowPhotoList(prev => !prev)}
                    >
                      {showPhotoList ? "画像一覧を隠す" : "画像一覧を表示"}
                    </Button>

                    <Button.Group style={{ marginLeft: 10 }}>
                      <Button
                        icon={<EditOutlined />}
                        type={mode === "edit" ? "primary" : "default"}
                        onClick={() => setMode("edit")}
                      />
                      <Button
                        icon={<SettingOutlined />}
                        type={mode === "setting" ? "primary" : "default"}
                        onClick={() => setMode("setting")}
                      />
                      <Button
                        icon={<EyeOutlined />}
                        type={mode === "view" ? "primary" : "default"}
                        onClick={() => setMode("view")}
                      />
                    </Button.Group>{() => setMode("view")}
                    <Button
                      icon={<ReloadOutlined  />} // ← import してなければ別アイコンでOK
                      onClick={resetZoomAndPosition}
                      style={{ marginLeft: "10px" }}
                    >
                      拡大リセット
                    </Button>

                    <Button
                      style={{ marginLeft: "10px" }}
                      icon={showBBoxTable ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      onClick={() => setShowBBoxTable(prev => !prev)}
                    >
                      {showBBoxTable ? "バウンディングボックス一覧を隠す" : "バウンディングボックス一覧を表示"}
                    </Button>

                    {mode === "edit" && (
                      <div
                        style={{

                          zIndex: 10, // Canvasより前面に配置

                        }}>
                        <Button
                          type="primary"
                          onClick={handleSaveAllChanges}
                          style={{ marginTop: 12 }}
                          disabled={!isChanged()}
                        >
                          クラウドに保存
                        </Button>

                      </div>
                    )}

                  </div>
                  <div
                    style={{
                      position: "fixed",
                      bottom: 40,
                      zIndex: 10,
                    }}
                  >
                    <Button
                      disabled={!selectedPhoto}
                      type="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={downloadWithBoundingBox}>
                      画像を出力する
                    </Button>
                  </div>
                  {selectedPhoto && (
                    <div
                      className="canvas-container"
                      style={{
                        position: "relative",
                        width: `${displayWidth}px`,
                        height: `${displayHeight}px`,
                        transform: `translate(${currentPosition.x}px, ${currentPosition.y}px) scale(${zoomLevel})`,
                        transformOrigin: `${zoomOrigin.x}px ${zoomOrigin.y}px`,

                        // 👇 カーソル条件分岐
                        cursor:
                          mode === "edit"
                            ? ctrlPressed
                              ? "crosshair"
                              : shiftPressed
                                ? "default"
                                : isDragging
                                  ? "grabbing"
                                  : "grab"
                            : isDragging
                              ? "grabbing"
                              : "grab",

                      }}
                    >
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
                          selectedPhoto.userName
                        )}%2F${encodeURIComponent(selectedPhoto.cultiverName)}%2F${encodeURIComponent(
                          selectedPhoto.photopath
                        )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`}
                        alt="Selected"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // アスペクト比を保って収める
                          visibility: imageLoaded ? "visible" : "hidden", // チラ見え防止
                        }}
                        onLoad={() => setImageLoaded(true)}
                        draggable={false}
                        onDragStart={(e) => e.preventDefault()}
                      />
                      <svg
                        width="100%"
                        height="100%"
                        style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none", zIndex: 20 }}
                      >
                        {/* 描画中のライン */}
                        {newLineStart && newLineEnd && (
                          <>
                            <line
                              x1={newLineStart.x / scaleX}
                              y1={newLineStart.y / scaleY}
                              x2={newLineEnd.x / scaleX}
                              y2={newLineEnd.y / scaleY}
                              stroke={lineColor}
                              strokeWidth={lineWidth}
                            />
                            <text
                              x={(newLineStart.x + newLineEnd.x) / 2 / scaleX}
                              y={(newLineStart.y + newLineEnd.y) / 2 / scaleY}
                              fill={lineColor}
                              fontSize="3"
                            >
                              {(() => {
                                const dx = newLineEnd.x - newLineStart.x;
                                const dy = newLineEnd.y - newLineStart.y;
                                const dist = Math.sqrt(dx * dx + dy * dy);
                                const micron = dist * 0.46; // ← ★ここを修正！
                                return `${dist.toFixed(2)} px / ${micron.toFixed(2)} µm`;
                              })()}
                            </text>
                          </>
                        )}


                        {/* 保存済みのライン一覧 */}
                        {imageLoaded && lines.map((line) => (
                          <g key={line.id}>
                            <line
                              x1={line.start.x / scaleX}
                              y1={line.start.y / scaleY}
                              x2={line.end.x / scaleX}
                              y2={line.end.y / scaleY}
                              stroke={lineColor}
                              strokeWidth={lineWidth}
                            />
                            <text
                              x={(line.start.x + line.end.x) / 2 / scaleX}
                              y={(line.start.y + line.end.y) / 2 / scaleY}
                              fill={lineColor}
                              fontSize="3"
                            >
                              {line.distance} px / {(line.distance * 0.46).toFixed(2)} µm
                            </text>
                          </g>
                        ))}
                      </svg>
                      <svg
                        width="100%"
                        height="100%"
                        style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none", zIndex: 20 }}
                      >
                        {/* 確定済みポリゴン */}
                        {polygons.map(p => (
                          <polygon
                            key={p.id}
                            points={p.points.map(p => `${p.x / scaleX},${p.y / scaleY}`).join(" ")}
                            fill="rgba(255, 255, 0, 0.3)"
                            stroke="yellow"
                            strokeWidth="1"
                          />
                        ))}

                        {/* 描画中のポリゴン（未確定） */}
                        {polygonPoints.length > 1 && (
                          <polyline
                            points={polygonPoints.map(p => `${p.x / scaleX},${p.y / scaleY}`).join(" ")}
                            fill="none"
                            stroke="orange"
                            strokeDasharray="2"
                            strokeWidth="1"
                          />
                        )}
                      </svg>

                      {mode === "edit" && newBoxStart && newBoxEnd && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            pointerEvents: "none",
                            zIndex: 15,
                          }}
                        >
                          <svg
                            width="100%"
                            height="100%"
                            style={{ position: "absolute", top: 0, left: 0 }}
                          >
                            {/* 👇🔴 矩形表示 */}
                            <rect
                              x={Math.min(newBoxStart.x, newBoxEnd.x) / scaleX}
                              y={Math.min(newBoxStart.y, newBoxEnd.y) / scaleY}
                              width={Math.abs(newBoxEnd.x - newBoxStart.x) / scaleX}
                              height={Math.abs(newBoxEnd.y - newBoxStart.y) / scaleY}
                              stroke={newBoxStart.type === "close" ? closeColor : openColor}
                              fill={newBoxStart.type === "close"
                                ? "rgba(0, 0, 255, 0.2)"
                                : "rgba(255, 0, 0, 0.2)"}
                              strokeWidth={1}
                            />

                          </svg>
                        </div>
                      )}



                      {imageLoaded && drawBoundingBox &&
                        boundingBoxes.map((box) => (
                          <div
                            key={box.key}
                            onMouseEnter={() => {
                              if (shiftPressed || ctrlPressed || newBoxStart || newLineStart) return;
                              setHoveredBoxKey(box.key);
                              extractBoxImage(box); // 🔥切り出し処理を呼び出し
                            }} // ホバー開始
                            onMouseLeave={() => {
                              if (shiftPressed || ctrlPressed || newBoxStart || newLineStart) return;
                              setHoveredBoxKey(null)
                            }} // ホバー終了}
                            style={{
                              position: "absolute",
                              top: box.y / scaleY,
                              left: box.x / scaleX,
                              width: (box.width / scaleX),
                              height: (box.height / scaleY),
                              border: "none", // ボーダーを無効化
                              boxShadow: `0 0 0 ${boxShadowWidth}px ${box.title === "open" ? openColor : closeColor}`,
                            }}
                            onClick={() => {
                              if (shiftPressed) return; // 👈追加
                              if (mode === "edit") {
                                setSelectedBoxKey(box.key);
                              }
                            }}

                          >
                            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                              {/* 💡 半透明の色付きオーバーレイ */}
                              {mode !== "edit" && hoveredBoxKey === box.key && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: box.title === "open"
                                      ? "rgba(255, 0, 0, 0.2)"
                                      : "rgba(0, 0, 255, 0.2)",
                                    pointerEvents: "none",
                                    zIndex: 1,
                                  }}
                                />
                              )}


                              {/* 👇 既存の番号とかの表示コンポーネント */}
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  right: 2,
                                  fontSize: "4px",
                                  color: box.title === "open" ? openColor : closeColor,
                                }}
                              >
                                {box.id && box.id !== "" ? box.id : box.key + 1}
                              </div>
                            </div>

                            {drawText && (
                              <div
                                style={{
                                  position: "absolute",
                                  lineHeight: "1px",
                                  marginTop: "1px",
                                  color: "black",
                                  whiteSpace: "nowrap",
                                  fontSize: "4px",
                                  color: box.title === "open" ? openColor : closeColor, // 条件によって色を変更
                                  top: -5, // ボックスの上端
                                  left: 0, // ボックスの左端
                                }}
                              >
                                {box.aperture.toFixed(2)} μm
                              </div>
                            )}
                            {/* ホバー中のボックスのみ情報を表示 */}
                            {hoveredBoxKey === box.key && mode !== "edit" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0%",
                                  left: "0%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: box.title === "open" ? openColor : closeColor,
                                  color: "black",
                                  padding: "1px",
                                  borderRadius: "2px",
                                  fontSize: "10px",
                                  textAlign: "left",
                                  lineHeight: "1",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div><strong>ID:</strong> {box.id ?? box.key + 1}</div>
                                <div><strong>aperture:</strong> {box.aperture.toFixed(2)} μm</div>
                                <div><strong>axisShort:</strong> {box.short.toFixed(2)} pixel</div>
                                <div><strong>axisLong:</strong> {box.long.toFixed(2)} pixel</div>
                              </div>
                            )}
                            {mode === "edit" && hoveredBoxKey === box.key && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: box.title === "open"
                                    ? "rgba(255, 0, 0, 0.2)"
                                    : "rgba(0, 0, 255, 0.2)",
                                  pointerEvents: "none", // ←マウス操作に影響しない
                                  zIndex: 1,
                                }}
                              />
                            )}

                          </div>

                        ))}
                    </div>

                  )}
                </div>
                {mode === "edit" && drawingMode !== "none" && (
                  <div
                    style={{
                      position: "fixed", // ← fixedに！
                      top: mouseScreenPos.y - 20,
                      left: mouseScreenPos.x + 10,
                      backgroundColor: "rgba(0,0,0,0.6)",
                      color: "white",
                      fontSize: "10px",
                      padding: "2px 6px",
                      borderRadius: "4px",
                      pointerEvents: "none",
                      zIndex: 1000,
                    }}
                  >
                    {drawingMode === "bbox" ? "📦 BBモード" : "📏 Lineモード"}
                  </div>
                )}

                {/* 右側: Editable テーブルによる BB 編集 */}
                {showBBoxTable && (
                  <div style={{
                    flex: "1 0 280px", // 固定幅にする（調整可能）
                    padding: "10px",
                    backgroundColor: "#f8f8f8",
                    borderLeft: "1px solid #ccc",
                    overflowY: "auto", // スクロールできるように
                  }}>
                    <h3>✏️ バウンディングボックス一覧</h3>

                    <Table
                      dataSource={boundingBoxes}
                      rowKey="key"
                      size="small"
                      bordered
                      pagination={false}
                      scroll={{ y: 400 }}
                      columns={[
                        {
                          title: "ID",
                          dataIndex: "id",
                          width: 60,
                          render: (text, record) => (
                            <span
                              style={{ cursor: "pointer", color: "#1890ff", textDecoration: "underline" }}
                              onClick={() => centerOnBox(record)}
                            >
                              {text}
                            </span>
                          )
                        },
                        {
                          title: "Title",
                          dataIndex: "title",
                          width: 90,
                          render: (_, record) => (
                            <Select
                              size="small"
                              value={record.title}
                              disabled={mode !== "edit"}
                              onChange={(val) => {
                                const updated = boundingBoxes.map(box =>
                                  box.key === record.key
                                    ? {
                                      ...box,
                                      title: val,
                                      aperture: val === "close" ? 0 : box.aperture,
                                      long: val === "close" ? 0 : box.long,
                                      short: val === "close" ? 0 : box.short,
                                    }
                                    : box
                                );
                                setBoundingBoxes(updated);
                              }}
                              options={[
                                { label: "open", value: "open" },
                                { label: "close", value: "close" },
                              ]}
                              style={{ width: "100%" }}
                            />
                          ),
                        },
                        {
                          title: "Aperture (μm)",
                          dataIndex: "aperture",
                          width: 100,
                          render: (_, record) => (
                            <InputNumber
                              size="small"
                              value={record.aperture.toFixed(2)}
                              step={0.01}
                              min={0}
                              disabled={mode !== "edit" || record.title === "close"}
                              onChange={(val) => {
                                const updated = boundingBoxes.map(box =>
                                  box.key === record.key ? { ...box, aperture: val } : box
                                );
                                setBoundingBoxes(updated);
                              }}
                            />
                          ),
                        },
                        {
                          title: "Long (px)",
                          dataIndex: "long",
                          width: 100,
                          render: (_, record) => (
                            <InputNumber
                              size="small"
                              value={Math.floor(Number(record.long))}
                              step={0.1}
                              min={0}
                              disabled={mode !== "edit" || record.title === "close"}
                              onChange={(val) => {
                                const updated = boundingBoxes.map(box =>
                                  box.key === record.key ? { ...box, long: val } : box
                                );
                                setBoundingBoxes(updated);
                              }}
                            />
                          ),
                        },
                        {
                          title: "Short (px)",
                          dataIndex: "short",
                          width: 100,
                          render: (_, record) => (
                            <InputNumber
                              size="small"
                              value={Math.floor(Number(record.short))}
                              step={0.1}
                              min={0}
                              disabled={mode !== "edit" || record.title === "close"}
                              onChange={(val) => {
                                const updated = boundingBoxes.map(box =>
                                  box.key === record.key ? { ...box, short: val } : box
                                );
                                setBoundingBoxes(updated);
                              }}
                            />
                          ),
                        },
                        {
                          title: "削除",
                          width: 60,
                          render: (_, record) => (
                            <Button
                              danger
                              size="small"
                              disabled={mode !== "edit"}
                              onClick={() => {
                                const updated = boundingBoxes.filter((box) => box.key !== record.key);
                                setBoundingBoxes(updated);
                              }}
                            >
                              削除
                            </Button>
                          ),
                        },
                      ]}
                    />
                    {lines.length > 0 && (
                      <>
                        <h3 style={{ marginTop: 20 }}>📏 計測ライン一覧</h3>

                        <Table
                          dataSource={lines}
                          rowKey="id"
                          size="small"
                          bordered
                          pagination={false}
                          scroll={{ y: 200 }}
                          columns={[
                            {
                              title: "ID",
                              dataIndex: "id",
                              width: 80,
                            },
                            {
                              title: "Start (x,y)",
                              render: (line, _, index) => (
                                <span>
                                  ({Math.round(line.start.x)}, {Math.round(line.start.y)})
                                </span>
                              ),
                            },
                            {
                              title: "End (x,y)",
                              render: (line) => (
                                <span>
                                  ({Math.round(line.end.x)}, {Math.round(line.end.y)})
                                </span>
                              ),
                            },
                            {
                              title: "距離",
                              render: (line) => {
                                return `${Number(line.distance).toFixed(1)} px / ${(line.distance * 0.46).toFixed(2)} µm`; // ← ここ！
                              },
                            }
                            ,
                            {
                              title: "削除",
                              render: (_, record) => (
                                <Button
                                  danger
                                  size="small"
                                  disabled={mode !== "edit"} // ← これを追加！
                                  onClick={() => {
                                    const updated = lines.filter((l) => l.id !== record.id);
                                    setLines(updated);
                                  }}
                                >
                                  削除
                                </Button>
                              ),
                              width: 70,
                            }

                          ]}

                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default BoundingBox;
